import { message, TableProps } from "antd";
import Button from "components/Button/Button";
import Label from "components/Label/Label";
import PageTitle from "components/PageTitle/PageTitle";
import PrintBlock, { DownloadFn } from "components/PrintBlock/PrintBlock";
import PrintContent from "components/PrintContent/PrintContent";
import Table from "components/Table/Table";
import TopBar from "components/TopBar/TopBar";
import { authUrl } from "config/path";
import useFilter from "hooks/useFilter";
import usePageDate from "hooks/usePageDate";
import usePrintLoading from "hooks/usePrintLoading";
import moment from "moment";
import getColumns from "pages/Requests/RequestsListFinal/getColumns";
import { FC, Key, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	useCreateAdminRequestMutation,
	useGetFinalRequestAdminPageContentQuery,
	useGetReportMutation,
} from "services/requestsServices";
import height from "staticContent/height";
import dateHelper from "utils/dateHelper";
import fileHelper from "utils/fileHelper";
import labels from "utils/labels";
import {
	transformFinalCategoriesToRequestTableData,
	transformFinalRequestCategories,
} from "utils/transformCategoriesToRequestTableData";
import TopBarInfo from "../components/TobBarInfo/TobBarInfo";
import styles from "./RequestsListFinal.module.scss";

const RequestsListFinal: FC = () => {
	const navigate = useNavigate();
	const { handleFilterChange, filterQuery } = useFilter<{ provider?: string }>();
	const [selectedProductIds, setSelectedProductIds] = useState<Key[]>([]);

	const [date] = usePageDate();
	const [loadingTypes, { removeLoadingType, addLoadingType }] = usePrintLoading();

	const { data, isLoading, isFetching } = useGetFinalRequestAdminPageContentQuery({
		Date: dateHelper.toRequestDate(date),
		...filterQuery,
	});

	const [createAdminRequest, { isLoading: isLoadingCreateRequest }] = useCreateAdminRequestMutation();

	const dataSource = useMemo(
		() => transformFinalRequestCategories(data?.finalRequestCategories),
		[data?.finalRequestCategories]
	);

	const printData = useMemo(
		() =>
			transformFinalCategoriesToRequestTableData(
				data?.finalRequestCategories.map((category) => ({
					...category,
					products: category.products.filter(({ product }) =>
						selectedProductIds.includes(`${category.productCategory.id}_${product.id}`)
					),
				}))
			),
		[data, selectedProductIds]
	);

	const [getReport] = useGetReportMutation();

	useEffect(() => {
		setSelectedProductIds([
			...dataSource.map((product) => product.key),
			...dataSource.reduce((acc, { children }) => acc.concat(children.map((item) => item.key)), []),
		]);
	}, [dataSource]);

	const onRedirectToAdvancedFinalRequest = () => {
		navigate(`${authUrl.Index.RequestsAdmin.AdvancedFinalList.url}?date=${dateHelper.toRequestDate(date)}`);
	};

	const rowSelection: TableProps<any>["rowSelection"] = {
		onChange: (selectedRowKeys) => {
			setSelectedProductIds(selectedRowKeys);
		},
		selectedRowKeys: selectedProductIds,
		checkStrictly: false,
	};

	const onDownload: DownloadFn = async (type) => {
		if (selectedProductIds?.length) {
			addLoadingType(type);

			const data = await getReport({
				date: date.unix() * 1000,
				reportType: type,
				reportElements: dataSource
					.reduce(
						(acc, { children }) =>
							acc.concat(
								children.map((product) => ({
									productId: product.id,
									totalQuantity: product.quantity,
									subdivisionCategory: product.subdivisionCategory,
									categoryName: product.productCategory.name,
									productName: product.productName,
									measurement: product.measurement,
									quantity: product.quantity,
									measurementMultiplicity: product.measurementMultiplicity,
								}))
							),
						[]
					)
					?.filter((item) => selectedProductIds.find((id) => id.toString().endsWith(item.productId.toString()))),
				provider: filterQuery?.provider?.toString(),
			}).unwrap();

			removeLoadingType(type);
			fileHelper.downloadBlob(data);
		}
	};

	const createAdminRequestHandler = () => {
		createAdminRequest(date.unix() * 1000).then(() => {
			message.success(`Заявка сформирована`);
		});
	};

	return (
		<>
			<TopBar alignTop>
				<PageTitle backUrl={`${authUrl.Index.RequestsAdmin.List.url}?date=${dateHelper.toRequestDate(date)}`}>
					Итоговая заявка
				</PageTitle>
				<TopBarInfo>
					<div className={styles.date}>
						<div className={styles.dateWrapper}>
							<Label noInput>Дата поставки</Label>
							<span className={styles.dateText}>{date.format("DD.MM.YYYY")}</span>
						</div>
						<div className={styles.dateWrapper}>
							<Label noInput>Дата создания</Label>
							<span className={styles.dateText}>
								{data?.creationDate ? moment(data.creationDate).format("DD.MM.YYYY HH:mm") : labels.dash}
							</span>
						</div>
					</div>
					<div className={styles.buttonsWrapper}>
						<PrintBlock
							onDownload={onDownload}
							loadingTypes={loadingTypes}
							printContent={
								<PrintContent
									data={printData}
									columns={getColumns()}
									title={`Итоговая заявка за ${date.format("DD.MM.YYYY")}`}
								/>
							}
							disabled={!dataSource?.length || !selectedProductIds?.length}
							printDate={dateHelper.toRequestDate(date)}
						/>
						<Button onClick={onRedirectToAdvancedFinalRequest}>Итоговая развернутая</Button>
						<Button
							onClick={createAdminRequestHandler}
							loading={isLoadingCreateRequest}
							disabled={!!data?.creationDate || !dataSource?.length}>
							Сформировать заявку
						</Button>
					</div>
				</TopBarInfo>
			</TopBar>
			<Table
				dataSource={dataSource}
				scroll={{ y: height.requestsListFinal }}
				columns={getColumns({ handleFilterChange })}
				loading={isLoading || isFetching}
				rowSelection={rowSelection}
				rowKey="key"
				expandable={{
					defaultExpandAllRows: true,
				}}
			/>
		</>
	);
};

export default RequestsListFinal;
