import { Form, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import Button from "components/Button/Button";
import usePage from "components/Context/PageContext";
import DatePicker from "components/DatePicker/DatePicker";
import Label from "components/Label/Label";
import Modal from "components/Modal/Modal";
import PageTitle from "components/PageTitle/PageTitle";
import Table from "components/Table/Table";
import TopBar from "components/TopBar/TopBar";
import { authUrl } from "config/path";
import useModal from "hooks/useModal";
import usePageDate from "hooks/usePageDate";
import getColumns from "pages/Requests/RequestsListAdmin/getColumns";
import { QuantityCellProps, getFormName } from "pages/Requests/components/QuantityCell/QuantityCell";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
	useDeleteAdminAsClientRequestMutation,
	useGetAdminAsClientPageContentQuery,
	useSubmitAdminAsClientRequestMutation,
	useUpdateClientRequestMutation,
} from "services/requestsServices";
import height from "staticContent/height";
import { RequestItem } from "types/models/EntityRequest";
import areEqual from "utils/areEqual";
import dateHelper from "utils/dateHelper";
import labels from "utils/labels";
import transformCategoriesToRequestTableData, {
	transformCategoriesToRequestMobileTableData,
} from "utils/transformCategoriesToRequestTableData";
import TopBarInfo from "../components/TobBarInfo/TobBarInfo";
import styles from "./RequestList.module.scss";
import { EntityProduct } from "types/models/EntityProduct";

const RequestsListAdmin: FC = () => {
	const [form] = useForm();
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { windowSize } = usePage();
	const { open, isOpen, close } = useModal();

	const [date, setDate] = usePageDate();
	const [initialValues, setInitialValues] = useState<Record<string, number>>({});
	const [disabledKeys, setDisabledKeys] = useState<string[]>([]);

	const {
		data: dataByClient,
		isLoading: loadingByClient,
		isFetching: fetchingByClient,
	} = useGetAdminAsClientPageContentQuery(
		{
			DeliveryDate: dateHelper.toRequestDate(date),
			ClientId: id,
		},
		{ skip: !id }
	);

	const { data, isLoading, isFetching } = useMemo(() => {
		return {
			data: dataByClient,
			isLoading: loadingByClient,
			isFetching: fetchingByClient,
		};
	}, [dataByClient, id, loadingByClient, fetchingByClient]);

	const [changeClientRequest, { isLoading: loadChange }] = useSubmitAdminAsClientRequestMutation();
	const [deleteAdminRequest, { isLoading: loadDeleteAdminRequest }] = useDeleteAdminAsClientRequestMutation();
	const [updateClientRequest, { isLoading: loadUpdate }] = useUpdateClientRequestMutation();

	const [productsQuantity, setProductsQuantity] = useState<Record<EntityProduct["id"], number>>({});

	const dataSource = useMemo(() => {
		return windowSize.width < 475
			? transformCategoriesToRequestMobileTableData(data?.categories)
			: transformCategoriesToRequestTableData(data?.categories);
	}, [data?.categories, windowSize.width]);

	useEffect(() => {
		setProductsQuantity(
			Object.fromEntries(dataSource.filter(({ isCat }) => !isCat).map(({ id, quantity }) => [id, quantity]))
		);
	}, [dataSource]);

	const mobileSize = useMemo(() => windowSize.width < 475, [windowSize.width]);
	const disabled = dataByClient?.isRequestCompleted;

	useEffect(() => {
		const initialValues = dataSource.reduce((result, product) => {
			return {
				...result,
				[getFormName(product.id)]: product.quantity,
			};
		}, {});

		setInitialValues(initialValues);
		form.setFieldsValue(initialValues);
	}, [dataSource]);
	const disabledSubmit = useMemo(() => {
		const initialValuesWithoutCategory = Object.fromEntries(
			Object.entries(initialValues).filter(([key]) => !key?.includes("category"))
		);

		return areEqual(initialValuesWithoutCategory, form.getFieldsValue());
	}, [initialValues, form.getFieldsValue()]);

	const onAddDisabledKey = (key: string) => {
		setDisabledKeys((prev) => Array.from(new Set([...prev, key])));
	};

	const onRemoveDisabledKey = (key: string) => {
		setDisabledKeys((prev) => prev.filter((k) => k !== key));
	};

	const onFinish = async (data: Record<string, number>) => {
		const requestItems = Object.entries(productsQuantity).map(([productId, quantity]) => ({
			productId: Number(productId),
			quantity,
		}));

		if (dataByClient.isRequestCompleted) {
			await updateClientRequest({
				clientId: Number(id),
				deliveryDate: date.unix() * 1000,
				requestItems,
			}).then(() => {
				message.success(`Заявка клиента ${dataByClient?.clientName} изменена`);
				setInitialValues(data);
			});
		} else {
			await changeClientRequest({
				clientId: Number(id),
				deliveryDate: date.unix() * 1000,
				requestItems,
			}).then(() => {
				message.success(`Заявка клиента ${dataByClient?.clientName} сохранена`);
			});
		}
	};

	const onClear = async () => {
		if (!!id && dataByClient?.isRequestCompleted) {
			await deleteAdminRequest({ requestDeliveryDate: dateHelper.toRequestDate(date), userId: id }).then(() => {
				message.success(`Заявка от клиента ${dataByClient.clientName} на ${date.format("DD.MM.YYYY")} удалена`);
				navigate(`${authUrl.Index.RequestsAdmin.List.url}?date=${dateHelper.toRequestDate(date)}`);
			});
		} else {
			form.resetFields();
		}
	};

	const handleChange: QuantityCellProps["onChange"] = (productId, quantity) => {
		setProductsQuantity({ ...productsQuantity, [productId]: quantity });
	};

	return (
		<>
			<Form name={"Requests"} form={form} onFinish={onFinish}>
				<TopBar alignTop>
					<PageTitle
						backUrl={!!id ? `${authUrl.Index.RequestsAdmin.List.url}?date=${dateHelper.toRequestDate(date)}` : false}>
						{[data?.clientName, data?.clientOrganization].join(" ").trim() || labels.dash}
					</PageTitle>
					<TopBarInfo>
						<div className={styles.date}>
							<div className={styles.datePickerWrapper}>
								<Label>Дата поставки</Label>
								<DatePicker width={240} minWidth accent value={date} onChange={setDate} />
							</div>
						</div>
						<div className={styles.buttons}>
							<Button
								type={"ghost"}
								onClick={open}
								disabled={disabled && !dataByClient?.isRequestCompleted}
								loading={loadDeleteAdminRequest}>
								Сбросить
							</Button>
							<Button htmlType={"submit"} disabled={disabledSubmit} loading={loadChange || loadUpdate}>
								{"Сохранить заявку"}
							</Button>
						</div>
					</TopBarInfo>
				</TopBar>

				<Table
					showHeader={!mobileSize}
					dataSource={dataSource}
					scroll={{
						y: windowSize.width > 475 ? height.requestsList.desktop : height.requestsList.mobile,
					}}
					columns={getColumns({
						disabledKeys,
						onAddDisabledKey,
						onRemoveDisabledKey,
						disabled: false,
						mobileSize,
						onChange: handleChange,
						productsQuantity,
					})}
					loading={isLoading || isFetching}
				/>
			</Form>
			<Modal
				title={`Сбросить заявку на ${date.format("DD.MM.YYYY")}`}
				open={isOpen}
				onCancel={close}
				onOk={() => {
					onClear();
					close();
				}}
			/>
		</>
	);
};

export default RequestsListAdmin;
