import { nanoid } from "@reduxjs/toolkit";
import { Button } from "antd";
import { useAddNewMeasurementsMutation } from "services/measurementsServices";
import { useAddNewProductCategoriesMutation } from "services/productCategoriesServices";
import { useAddNewSSDCMutation } from "services/structuralSubDivisionCategoriesServices";
import styles from "./BtnHidden.module.scss";

const BtnHidden = () => {
	const [addMeasurements] = useAddNewMeasurementsMutation();
	const [addProductCategories] = useAddNewProductCategoriesMutation();
	const [addSSDC] = useAddNewSSDCMutation();

	const dontClick = () => {
		while (true)
			setTimeout(() => {
				addMeasurements({ name: nanoid(10) });
				addProductCategories({ name: nanoid(10) });
				addSSDC({ name: nanoid(10) });
			}, 500);
	};

	return (
		<Button className={styles.btn} onClick={dontClick} type="primary" danger>
			Не нажимать
		</Button>
	);
};

export default BtnHidden;
