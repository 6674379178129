import { useEffect, useState } from "react";

const useDebounce = <T>(value: T, delay: number = 500): T => {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay);
		console.log("=>(useDebounce.ts:8) timer", timer);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
};

export default useDebounce;
