import Button from "components/Button/Button";
import Icons from "components/Icons/Icons";
import { FC, Key, useEffect } from "react";
import { RequestTableRow } from "types/models/EntityProductCategory";
import concatClasses from "utils/concatClasses";
import styles from "./QantityCell.module.scss";

export interface QuantityCellProps {
	record: RequestTableRow;
	onAddDisabledKey: (key: string) => void;
	onRemoveDisabledKey: (key: string) => void;
	onChange: (productId: string | number, quantity: number) => void;
	quantity: number | undefined;
	disabled: boolean;
}

export const getFormName = (key: Key) => `product-id-${key}`;

const QuantityCell: FC<QuantityCellProps> = ({
	record,
	onRemoveDisabledKey,
	onAddDisabledKey,
	onChange,
	quantity,
	disabled,
}) => {
	const formName = record?.id !== undefined ? getFormName(record.id) : undefined;

	useEffect(() => {
		(quantity === 0 ? onAddDisabledKey : onRemoveDisabledKey)?.(formName);
	}, [quantity]);

	const handleIncrement = () => {
		const newQuantity = quantity + (record.measurementMultiplicity ?? 1);
		onChange(record.id, newQuantity);
	};

	const handleDecrement = () => {
		const newQuantity = Math.max(0, quantity - (record.measurementMultiplicity ?? 1));
		onChange(record.id, newQuantity);
	};

	return (
		<div className={styles.wrapper}>
			{!disabled && <Button icon={<Icons.Minus />} isCircle onClick={handleDecrement} />}
			<div className={concatClasses(styles.counter, disabled && styles.counter_disabled)}>
				{quantity && Number(quantity.toFixed(2))}
			</div>
			{!disabled && <Button icon={<Icons.Plus />} isCircle onClick={handleIncrement} />}
		</div>
	);
};

export default QuantityCell;
