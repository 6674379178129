import { FormInstance, TableProps } from "antd";
import ActionsCell from "pages/Requests/components/ActionsCell";
import { RequestTableRow } from "types/models/EntityProductCategory";
import QuantityCell from "../components/QuantityCell/QuantityCell";
import { EntityProduct } from "types/models/EntityProduct";

interface GetColumnsArgs {
	disabledKeys: string[];
	onAddDisabledKey: (key: string) => void;
	onRemoveDisabledKey: (key: string) => void;
	mobileSize?: boolean;
	disabled: boolean;
	onChange: (productId: string | number, quantity: number) => void;
	productsQuantity: Record<EntityProduct["id"], number>;
}

const getColumns = ({
	disabledKeys,
	onRemoveDisabledKey,
	onAddDisabledKey,
	mobileSize,
	disabled,
	onChange,
	productsQuantity,
}: GetColumnsArgs): TableProps<RequestTableRow>["columns"] => {
	const originColumns = [
		{
			dataIndex: "categoryName",
			title: "Категория",
			hidden: mobileSize,
		},
		{
			dataIndex: "productName",
			title: "Наименование",
			width: mobileSize ? "24%" : "auto",
		},
		{
			dataIndex: "measurement",
			title: "Ед.изм.",
			width: mobileSize ? "4%" : "auto",
		},
		{
			dataIndex: "quantity",
			title: "Кол-во",
			width: mobileSize ? "16%" : "auto",
			render: (_: unknown, record: RequestTableRow) =>
				!record.isCat && (
					<QuantityCell
						onAddDisabledKey={onAddDisabledKey}
						onRemoveDisabledKey={onRemoveDisabledKey}
						record={record}
						onChange={onChange}
						quantity={productsQuantity[Number(record.id)]}
						disabled={disabled}
					/>
				),
		},
		{
			dataIndex: "actions",
			render: (_: undefined, { isCat, ...record }: RequestTableRow) =>
				!isCat && (
					<ActionsCell
						onClick={() => onChange(record.id, 0)}
						data={record}
						disabledKeys={disabledKeys}
						disabled={disabled}
						hiddenText={mobileSize}
					/>
				),
			width: mobileSize ? "7%" : 205,
		},
	].filter((item) => !item.hidden);

	return originColumns;
};

export default getColumns;
