import Button from "components/Button/Button";
import Icons from "components/Icons/Icons";
import { getFormName } from "pages/Requests/components/QuantityCell/QuantityCell";
import { FC, MouseEventHandler } from "react";
import { RequestTableRow } from "types/models/EntityProductCategory";

interface ActionsCellProps {
	data: RequestTableRow;
	disabledKeys: string[];
	disabled?: boolean;
	hiddenText?: boolean;
	onClick?: MouseEventHandler;
}

const ActionsCell: FC<ActionsCellProps> = ({ data, disabledKeys, disabled, hiddenText = false, onClick }) =>
	(
		<Button
			type={"text"}
			disabled={disabled || disabledKeys.includes(getFormName(data.id))}
			onClick={onClick}
			icon={<Icons.Delete />}>
			{!hiddenText && "Убрать из заявки"}
		</Button>
	);

export default ActionsCell;
