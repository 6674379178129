import { FormInstance, TableProps } from "antd";
import ActionsCell from "pages/Requests/components/ActionsCell";
import { ReactNode } from "react";
import { RequestTableRow } from "types/models/EntityProductCategory";
import QuantityCell, { QuantityCellProps } from "../components/QuantityCell/QuantityCell";

interface GetColumnsArgs extends Pick<QuantityCellProps, "onChange"> {
	disabledKeys: string[];
	onAddDisabledKey: (key: string) => void;
	onRemoveDisabledKey: (key: string) => void;
	mobileSize?: boolean;
	disabled: boolean;
	productsQuantity: Record<RequestTableRow["id"], number>;
}

const getColumns = ({
	disabledKeys,
	onRemoveDisabledKey,
	onAddDisabledKey,
	mobileSize,
	disabled,
	onChange,
	productsQuantity,
}: GetColumnsArgs): TableProps<RequestTableRow>["columns"] => {
	const originColumns = [
		{
			dataIndex: "categoryName",
			title: "Категория",
			hidden: mobileSize,
			width: 200,
		},
		{
			dataIndex: "productName",
			title: "Наименование",
			width: mobileSize ? "20%" : 200,
		},
		{
			dataIndex: "measurement",
			title: "Ед.изм.",
			width: mobileSize ? "8%" : 70,
		},
		{
			dataIndex: "quantity",
			title: "Кол-во",
			width: mobileSize ? "20%" : 155,
			editable: true,
			render: (_: unknown, record: RequestTableRow) =>
				!record.isCat && (
					<QuantityCell
						onAddDisabledKey={onAddDisabledKey}
						onRemoveDisabledKey={onRemoveDisabledKey}
						record={record}
						onChange={onChange}
						quantity={productsQuantity[record.id]}
						disabled={disabled}
					/>
				),
		},
		{
			dataIndex: "actions",
			render: (_: undefined, { isCat, ...record }: RequestTableRow) =>
				!isCat && (
					<ActionsCell
						onClick={() => onChange(record.id, 0)}
						data={record}
						disabledKeys={disabledKeys}
						disabled={disabled}
						hiddenText={mobileSize}
					/>
				),

			width: mobileSize ? "8%" : 250,
		},
	].filter((item) => !item.hidden);

	return originColumns;
};

export default getColumns;
